import React from 'react';
import {LoadingComponent,} from '@queensland-running/qr-components';
import {StepProps} from '../event-registration-flow';
import {CompetitionType, MultiRegistration} from '../multi-registration-flow';
import {useCompetitionDetailsQuery} from '../queries.generated';
import {AdditionalRegistrationDetails} from "./contact-details/additional-details";

interface RegisterForEventProps {
  competitionId: string;
}

const useRegisterForEvent = ({competitionId}: RegisterForEventProps) => {
  const {data: eventData, loading} = useCompetitionDetailsQuery({
    variables: {
      id: competitionId,
    },
    errorPolicy: 'all'
  });

  return {competition: eventData?.competition, userAccount: eventData?.userAccount, loading};
};
export const ContactDetailsStep = ({handleNext, competitionId}: StepProps) => {
  const {competition, loading} = useRegisterForEvent({competitionId});

  if (loading) {
    return (
      <>
        <br/>
        <LoadingComponent message="Loading contact details..."/>
      </>
    );
  }
  if (!competition) {
    return (
      <>
        <br/>
        <>No competition selected</>
      </>
    );
  }

  return (
    <MultiRegistration onSubmit={handleNext}
                       competition={competition as CompetitionType}
                       details={AdditionalRegistrationDetails}/>
  );
};
