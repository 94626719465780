import {
    AnchorLink,
    Button,
} from "@queensland-running/qr-components";
import {RouteKeys, Routes} from "@constants";
import {Grid} from "@material-ui/core";
import React from "react";
import {CompetitionType} from "../../multi-registration-flow";
import {useTranslation} from "react-i18next";

const AdditionalRegistrationDetails = (_: { competition: CompetitionType }) => {
    const { t } = useTranslation('common')

    // const Fees = ({competition}: { competition: CompetitionType }) => {
    //     if (competition) {
    //         switch (competition.__typename) {
    //             case 'WeeklyCompetition':
    //                 return (
    //                     <WeeklyCompetitionFees
    //                         fees={competition.fees!}
    //                         prepaidMembershipInfo={`${Routes[RouteKeys.registration]}#prepaidRegistration`}
    //                     />
    //                 );
    //             case 'RockettRelays':
    //                 return <RockettRelaysCompetitionFees fees={competition.fees!}/>;
    //             case 'AllSchoolsChampionships':
    //             case 'WinterChampionships':
    //             default:
    //                 return null;
    //         }
    //     }
    //     return null;
    // };

    return (
        <Grid item xs={12}>
            <AnchorLink to={`${Routes[RouteKeys.registration]}`} target="_blank">
                <Button color="primary" size="small" title={t('competition.buttons.registrationDetails')}/>
            </AnchorLink>
            {/*<br/>*/}
            {/*<Fees competition={competition}/>*/}
        </Grid>
    );
};

export {AdditionalRegistrationDetails}
