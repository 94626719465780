import React, {useEffect} from 'react';
import {StepProps} from '../event-registration-flow';
import {LoadingComponent} from "@queensland-running/qr-components";
import {WeeklyRegistrationConfirmation} from "./confirmation/weekly-registration-confirmation";
import {useWeeklyRegistrationContext} from "../../../../contexts";
import {
  AllSchoolsChampionshipsRegistrationConfirmation
} from "./confirmation/all-schools-championships-registration-confirmation";

export const SelectConfirmationStep  = (props: StepProps) => {
const { isRegistrationReady } = useWeeklyRegistrationContext();

  useEffect(() => {
    if (isRegistrationReady === false) {
      const timer = setTimeout(() => {
        props.handleBack!()
      }, 1500)
      return () => clearTimeout(timer)
    }
  }, [isRegistrationReady, props.handleBack])

  if (isRegistrationReady === null) {
    return (
      <>
        <br/>
        <LoadingComponent message="Confirming registration..."/>
      </>
    );
  }

  switch (props.competitionType) {
    case "AllSchoolsChampionships":
      return <AllSchoolsChampionshipsRegistrationConfirmation {...props} />
    case 'WeeklyCompetition':
    default:
      return <WeeklyRegistrationConfirmation {...props} />
  }
};
