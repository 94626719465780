import React from 'react';
import Typography from '@material-ui/core/Typography';
import {StepNavigator, StepProps} from '../../event-registration-flow';
import {useRegisterContactDetailsForCompetitionMutation} from "../../mutations.generated";
import {useWeeklyRegistrationContext} from "../../../../../contexts";
import {Grid, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery} from "@material-ui/core";
import {FieldCustomValue, FieldValue} from "@queensland-running/qr-components";
import moment from "moment";

export const AllSchoolsChampionshipsRegistrationConfirmation = ({handleNext, handleBack, competitionId, competitionDay}: StepProps) => {
  const {contactDetails, runnerDetails} = useWeeklyRegistrationContext();
  const [registerRunnersForEvent] = useRegisterContactDetailsForCompetitionMutation({
    onCompleted() {
      handleNext!();
    },
  });
  const m = useMediaQuery("(max-width: 960px)")

  const handleSubmit = () => {
    registerRunnersForEvent({
      variables: {
        input: {
          competitionId,
          username: contactDetails?.emailAddress,
          contactDetails: {
            firstName: contactDetails?.firstName ?? "",
            lastName: contactDetails?.lastName ?? "",
          },
          runnerDetails: runnerDetails
            .map((runner) => ({
              id: runner.id,
              bibNumber: runner.bibNumber,
              club: runner.club,
              firstName: runner.firstName,
              lastName: runner.lastName,
              gender: runner.gender,
              dateOfBirth: moment(runner.dateOfBirth).isValid() ? runner.dateOfBirth : null,
              schoolPrep: (runner as any).isSchoolPrepMeet,
            }))
          // .map(
          //   ({id, bibNumber, club, firstName, lastName, ...runner}: Runner): RegisterRunner => ({
          //     id,
          //     bibNumber,
          //     club,
          //     firstName,
          //     lastName,
          //     gender: null,
          //     dateOfBirth: runner.dateOfBirth, //moment(runner.dateOfBirth!).format('YYYY-MM-DD')
          //   }),
          // ),
        },
      },
    });
  };

  return (
    <>
      <Typography variant="h5" color="primary">
        Confirm
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FieldValue label="Email Address" name="emailAddress" value={contactDetails?.emailAddress}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldValue label="First Name" name="firstName" value={contactDetails?.firstName}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldValue label="Last Name" name="lastName" value={contactDetails?.lastName}/>
        </Grid>

        <Grid item xs={12}>
          {
            m ?
            <FieldCustomValue label="Runners" name='runners' CustomValue={() =>
              <>
              {
                runnerDetails.map((detail, index) =>
                  <Grid container key={index}>
                    <Grid item xs={12}><Typography variant='h5' style={{marginTop: '10px', fontWeight: 'bold'}}>Runner {detail["index"] + 1}</Typography></Grid>
                    <Grid item xs={12} sm={6}>
                      <FieldValue label="Name" name="name" value={`${[detail.lastName, detail.firstName].join(', ')} ${detail.gender ? `(${detail.gender})` : ''}`} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FieldValue label="Date of Birth" name="dob" value={detail['dateOfBirth'] &&
                        `${detail['dateOfBirth'] && moment(detail['dateOfBirth']).format('DD/MM/YYYY')} (${moment(competitionDay).endOf('year').diff(
                          detail['dateOfBirth'],
                          'years',
                        )} y/o)`}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FieldValue label="Bib Number" name="bib" value={detail.bibNumber ?? 'N/A'}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FieldValue label="Affiliation" name="club" value={detail.club}/>
                    </Grid>
                  </Grid>)
              }
              </>
            }/>
              :(<FieldCustomValue label="Runners" name="runners" CustomValue={() =>
              <Table>
                <TableHead><TableRow><TableCell>Name</TableCell><TableCell>Date of
                  Birth</TableCell><TableCell>Bib</TableCell><TableCell>Affiliation</TableCell></TableRow></TableHead>
                <TableBody>
                  {
                    runnerDetails.map((detail, index) =>
                      <TableRow key={index}><TableCell>{detail.lastName}, {detail.firstName} {detail.gender ? `(${detail.gender})` : ''}</TableCell><TableCell>{detail['dateOfBirth'] &&
                        `${detail['dateOfBirth'] && moment(detail['dateOfBirth']).format('DD/MM/YYYY')} (${moment(competitionDay).endOf('year').diff(
                          detail['dateOfBirth'],
                          'years',
                        )} y/o)`}</TableCell><TableCell>{detail.bibNumber ?? 'N/A'}</TableCell><TableCell>{detail.club}</TableCell></TableRow>
                    )
                  }
                </TableBody>
              </Table>
            }/>)
          }

        </Grid>
      </Grid>
      <br/>
      <br/>
      <Typography>Note: Age is calculated based on age on {moment(competitionDay).endOf('year').format('DD MMM YYYY')}</Typography>
      <br/>
      <StepNavigator
        handleBack={handleBack}
        backText={'Back'}
        handleNext={handleSubmit}
        nextText={'Next'}
      />
    </>
  );
};
