import {Typography} from "@material-ui/core";
import React from "react";

const entryFormUrl = '/assets/pdfs/forms/2025 Casual Entry Form.pdf';
const winterChampionships2021EntryFormUrl = '/assets/pdfs/forms/2021 WC Entry Form.pdf';
const rockettRelays2022OneHourEntryFormUrl = '/assets/pdfs/forms/1 Hour Relay Team Entry 2022.pdf';
const rockettRelays2022TwoHourEntryFormUrl = '/assets/pdfs/forms/2 Hour Relay Team Entry 2022.pdf';
const sampleTallyOneHourFormUrl = '/assets/pdfs/forms/Rockett-Relays-Team-Tally-Sheet-One-Hour-Sample.pdf';
const sampleTallyTwoHourFormUrl = '/assets/pdfs/forms/Rockett-Relays-Team-Tally-Sheet-Two-Hour-Sample.pdf';

const CompetitionForms = ({agenda}: { agenda: string}) => {
    switch (agenda) {
        case 'WeeklyCompetition':
            return (
                <>
                    <Typography variant="h5" color="secondary">
                        Forms
                    </Typography>
                    <Typography component="a" href={entryFormUrl} display="block" target="_blank">
                        Weekly Entry Form
                    </Typography>
                </>
            );
        case 'AllSchoolsChampionships':
            return (
                <>
                    {/*<Typography variant="h5" color="secondary">*/}
                    {/*    Forms*/}
                    {/*</Typography>*/}
                    {/*<Typography component="a" href={entryFormUrl} display="block" target="_blank">*/}
                    {/*    2019 All Schools Championship Entry Form*/}
                    {/*</Typography>*/}
                </>
            );
        case 'WinterChampionships':
            return (
                <>
                    <Typography variant="h5" color="secondary">
                        Forms
                    </Typography>
                    <Typography component="a" href={winterChampionships2021EntryFormUrl} display="block"
                                target="_blank">
                        2021 Winter Championships Entry Form
                    </Typography>
                </>
            );
        case 'RockettRelays':
            return (
                <>
                    <Typography variant="h5" color="secondary">
                        Team Entry Forms
                    </Typography>
                    <Typography component="a" href={rockettRelays2022OneHourEntryFormUrl} display="block"
                                target="_blank">
                        2022 Team Entry Form - One Hour
                    </Typography>
                    <Typography component="a" href={rockettRelays2022TwoHourEntryFormUrl} display="block"
                                target="_blank">
                        2022 Team Entry Form - Two Hour
                    </Typography>
                    <br/>
                    <Typography variant="h5" color="secondary">
                        Sample Team Tally Sheets
                    </Typography>
                    <Typography component="a" href={sampleTallyOneHourFormUrl} display="block" target="_blank">
                        Sample Team Tally Sheet - One Hour
                    </Typography>
                    <Typography component="a" href={sampleTallyTwoHourFormUrl} display="block" target="_blank">
                        Sample Team Tally Sheet - Two Hour
                    </Typography>
                </>
            );
        default:
            return null;
    }
};

export { CompetitionForms }