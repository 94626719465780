import {Park} from '@generated/schema';

export enum RouteKeys {
  appBase = 'appBase',
  about = 'about',
  account = 'account',
  notificationSettings = 'notificationSettings',
  events = 'events',
  calendar = 'calendar',
  allSchoolsChampionships = 'allSchoolsChampionships',
  winterChampionships = 'winterChampionships',
  fees = 'fees',
  venues = 'venues',
  venue = 'venue',
  programs = 'programs',
  event = 'event',
  volunteer = 'volunteer',
  rockettRelays = 'rockettRelays',
  courseSignage = 'courseSignage',
  registration = 'registration',
  runners = 'runners',
  purchaseHistory = 'purchaseHistory',
}

export const Routes = {
  [RouteKeys.appBase]: '/',
  [RouteKeys.about]: '/about',
  [RouteKeys.account]: '/account',
  [RouteKeys.notificationSettings]: '/notification-settings',
  [RouteKeys.runners]: '/runners',
  [RouteKeys.purchaseHistory]: '/purchase-history',
  [RouteKeys.events]: '/events',
  [RouteKeys.calendar]: '/calendar',
  [RouteKeys.event]: '/event',
  [RouteKeys.allSchoolsChampionships]: '/events/all-schools-championships',
  [RouteKeys.winterChampionships]: '/events/winter-championships',
  [RouteKeys.rockettRelays]: '/events/rockett-relays',
  [RouteKeys.fees]: '/fees',
  [RouteKeys.venues]: '/venues',
  [RouteKeys.venue]: '/venues/:name',
  [RouteKeys.programs]: '/programs',
  [RouteKeys.volunteer]: '/volunteer',
  [RouteKeys.registration]: '/registration',
  [RouteKeys.courseSignage]: '/course-signage',
  // [RouteKeys.events]: {
  //   allSchools: '/events/all-schools',
};

export const RouteLabels = {
  [RouteKeys.appBase]: 'Home',
  [RouteKeys.about]: 'About',
  [RouteKeys.account]: 'Account',
  [RouteKeys.notificationSettings]: 'Notification Settings',
  [RouteKeys.runners]: 'Runners',
  [RouteKeys.purchaseHistory]: 'Purchase History',
  [RouteKeys.events]: 'Events',
  [RouteKeys.event]: 'Event',
  [RouteKeys.calendar]: 'Calendar',
  [RouteKeys.allSchoolsChampionships]: 'All Schools Championships',
  [RouteKeys.winterChampionships]: 'Winter Championships',
  [RouteKeys.rockettRelays]: 'Rockett Relays',
  [RouteKeys.fees]: 'Fees',
  [RouteKeys.venues]: 'Venues',
  [RouteKeys.venue]: 'Venue',
  [RouteKeys.programs]: 'Programs',
  [RouteKeys.volunteer]: 'Volunteer',
  [RouteKeys.courseSignage]: 'Course Signage',
  [RouteKeys.registration]: 'Registration & Fees',
};

export const mapRoute = (url: Park['url']) => Routes.venues + '/' + url;
